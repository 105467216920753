@font-face {
  font-family: 'Freight Sans Pro';
  src: local('Freight Sans Pro Light'), local('Freight-Sans-Pro-Light'),
    url(./Fonts/FreightSansPro-Light.woff) format('woff');
}

@font-face {
  font-family: 'EBGaramond';
  src: local('EBGaramond'), local('EBGaramond'),
    url(./Fonts/EBGaramond.woff) format('woff');
}

body {
  color: #696C70;
  margin: 0;
  font-family: Freight Sans Pro !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body #root {
  --font-family: Freight Sans Pro;
  --primary: #9C5B4B;
  --secondary: #9C5B4B;
}

.sd-completedpage {
  --font-family: EBGaramond;
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader {
  border: 2rem solid #f3f3f3;
  border-radius: 50%;
  border-top: 2rem solid #9c5b4b;
  width: 10rem;
  height: 10rem;
  margin-top: 50px;
  -webkit-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
}

.rotate {
  animation: rotation 2s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotateY(0);
  }

  to {
    transform: rotateY(360deg);
  }
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(-180deg);
  }

  100% {
    transform: rotate(180eg);
  }
}

.sd-question__content .sd-scrollable-container {
  margin: 0 auto;
}

@keyframes pulsate {
  0% {
    transform: scale(0.6, 0.6);
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    transform: scale(1, 1);
    opacity: 0;
  }
}

@keyframes heart-pulse {
  0% {
    transform: scale(0.75, 0.75);
  }

  30% {
    transform: scale(1, 1);
  }
}

.pulse-base {
  position: relative;
  height: 60px;
  width: 60px;
  border: 15px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  margin: 0 auto;
}

.pulse-circle {
  background: #9c5b4b;
}

.pulse-base:before {
  content: '';
  border: 15px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  height: 60px;
  width: 60px;
  position: absolute;
  left: -15px;
  bottom: -15px;
  animation: pulsate 1.6s ease-out;
  animation-iteration-count: infinite;
  opacity: 0;
  z-index: 99;
}

.heart {
  font-size: 90px;
  line-height: 1;
  color: red;
  margin: 0 auto;
  padding: 0;
  animation: heart-pulse 0.8s ease-out alternate;
  animation-iteration-count: infinite;
}

.survey-result {
  font-family: EBGaramond;
  background-color: #f3f3f3;
  padding-bottom: 50px;
  padding-top: 50px;
}

.sd-body.sd-completedpage {
  display: none;
}